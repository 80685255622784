/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers';
import type { Provider } from '@ethersproject/providers';
import type { Web3Predictions, Web3PredictionsInterface } from '../Web3Predictions';

const _abi = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'target',
        type: 'address',
      },
    ],
    name: 'AddressEmptyCode',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'ERC1967InvalidImplementation',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ERC1967NonPayable',
    type: 'error',
  },
  {
    inputs: [],
    name: 'FailedCall',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidInitialization',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotInitializing',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'OwnableInvalidOwner',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'OwnableUnauthorizedAccount',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ReentrancyGuardReentrantCall',
    type: 'error',
  },
  {
    inputs: [],
    name: 'UUPSUnauthorizedCallContext',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'slot',
        type: 'bytes32',
      },
    ],
    name: 'UUPSUnsupportedProxiableUUID',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'BetBear',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'BetBull',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint64',
        name: 'version',
        type: 'uint64',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'closeTimestamp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'closePrice',
        type: 'int256',
      },
    ],
    name: 'RoundClosed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'lockTimestamp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'lockPrice',
        type: 'int256',
      },
    ],
    name: 'RoundLocked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'startTimestamp',
        type: 'uint256',
      },
    ],
    name: 'RoundOpened',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'lockPrice',
        type: 'int256',
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'closePrice',
        type: 'int256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewardAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'outcome',
        type: 'string',
      },
    ],
    name: 'RoundResult',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'Upgraded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'winner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'WinningsPaid',
    type: 'event',
  },
  {
    inputs: [],
    name: 'UPGRADE_INTERFACE_VERSION',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'enum PredictionsMagicSquare.Position',
        name: '_position',
        type: 'uint8',
      },
    ],
    name: 'bet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newMinBetAmount',
        type: 'uint256',
      },
    ],
    name: 'changeMinBetAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_newOperator',
        type: 'address',
      },
    ],
    name: 'changeOperator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newRoundTime',
        type: 'uint256',
      },
    ],
    name: 'changeRoundTime',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_newTreasuryAddress',
        type: 'address',
      },
    ],
    name: 'changeTreasuryAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newTreasuryPercent',
        type: 'uint256',
      },
    ],
    name: 'changeTreasuryPercent',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'collectedFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'defineWinners',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'forceWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getActiveLockedClosedRounds',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'lockPrice',
            type: 'int256',
          },
          {
            internalType: 'int256',
            name: 'closePrice',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'totalAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bullAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bearAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rewardAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'oracleCalled',
            type: 'bool',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBull',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBear',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'winner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Winners[]',
            name: 'winners',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.Round[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getLastRoundInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'lockPrice',
            type: 'int256',
          },
          {
            internalType: 'int256',
            name: 'closePrice',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'totalAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bullAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bearAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rewardAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'oracleCalled',
            type: 'bool',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBull',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBear',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'winner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Winners[]',
            name: 'winners',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.Round',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_lastNums',
        type: 'uint256',
      },
    ],
    name: 'getLastRoundInfoLastIndx',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'lockPrice',
            type: 'int256',
          },
          {
            internalType: 'int256',
            name: 'closePrice',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'totalAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bullAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bearAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rewardAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'oracleCalled',
            type: 'bool',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBull',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBear',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'winner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Winners[]',
            name: 'winners',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.Round[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_roundId',
        type: 'uint256',
      },
    ],
    name: 'getRoundInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'int256',
            name: 'lockPrice',
            type: 'int256',
          },
          {
            internalType: 'int256',
            name: 'closePrice',
            type: 'int256',
          },
          {
            internalType: 'uint256',
            name: 'totalAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bullAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'bearAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rewardAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'oracleCalled',
            type: 'bool',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBull',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'wallet',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Participants[]',
            name: 'participantsBear',
            type: 'tuple[]',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'winner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            internalType: 'struct PredictionsMagicSquare.Winners[]',
            name: 'winners',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.Round',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_wallet',
        type: 'address',
      },
    ],
    name: 'getRoundsForUser',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_wallet',
        type: 'address',
      },
    ],
    name: 'getWalletAndRounds',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'enum PredictionsMagicSquare.Position',
            name: 'position',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'won',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'claimed',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'draw',
            type: 'bool',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.BetInfo[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_wallet',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_lastNums',
        type: 'uint256',
      },
    ],
    name: 'getWalletAndRoundsLastIndx',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'roundId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'closeTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'enum PredictionsMagicSquare.Position',
            name: 'position',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'won',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'claimed',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'draw',
            type: 'bool',
          },
        ],
        internalType: 'struct PredictionsMagicSquare.BetInfo[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_treasury',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'minBetAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operator',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'oracle',
    outputs: [
      {
        internalType: 'contract AggregatorV3Interface',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'oracleLatestRoundId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pool',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'proxiableUUID',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'roundId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'roundTime',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'sqr',
    outputs: [
      {
        internalType: 'contract IPermitToken',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'treasuryAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'treasuryPercent',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'usdt',
    outputs: [
      {
        internalType: 'contract IPermitToken',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export class Web3Predictions__factory {
  static readonly abi = _abi;
  static createInterface(): Web3PredictionsInterface {
    return new utils.Interface(_abi) as Web3PredictionsInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Web3Predictions {
    return new Contract(address, _abi, signerOrProvider) as Web3Predictions;
  }
}
